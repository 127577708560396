import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row, Table } from 'reactstrap';
import { ICrudGetAllAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntities } from './produit.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT, AUTHORITIES } from 'app/config/constants';
import { hasAnyAuthority } from 'app/shared/auth/private-route';

export interface IProduitProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export const Produit = (props: IProduitProps) => {
  useEffect(() => {
    props.getEntities();
  }, []);

  const { produitList, match, loading, isConseiller } = props;
  return (
    <div>
      <h2 id="produit-heading">
        Produits
        {!isConseiller && (
          <Link to={`${match.url}/new`} className="btn btn-cyan float-right jh-create-entity" id="jh-create-entity">
            <FontAwesomeIcon icon="plus" />
            &nbsp; Nouveau produit
          </Link>
        )}
      </h2>
      <div className="table-responsive">
        {produitList && produitList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th>Nom</th>
                <th>Description</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {produitList.map((produit, i) => (
                <tr key={`entity-${i}`}>
                  <td>{produit.nom}</td>
                  <td>{produit.description}</td>
                  <td className="text-right">
                    <div className="btn-group flex-btn-group-container">
                    {isConseiller && (
                      <Button tag={Link} to={`${match.url}/${produit.id}`} color="info" size="sm" style={{marginRight:'5px'}}>
                        <FontAwesomeIcon icon="eye" /> <span className="d-none d-md-inline">Consulter</span>
                      </Button>
                    )}
                      {!isConseiller && (
                        <>
                          <Button tag={Link} to={`${match.url}/${produit.id}/edit`} color="primary" size="sm" style={{marginRight:'5px'}}>
                            <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Modifier</span>
                          </Button>
                          <Button tag={Link} to={`${match.url}/${produit.id}/delete`} color="danger" size="sm">
                            <FontAwesomeIcon icon="trash" /> <span className="d-none d-md-inline">Supprimer</span>
                          </Button>
                        </>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && <div className="alert alert-warning">Aucun produit trouvé</div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ produit, authentication }: IRootState) => ({
  produitList: produit.entities,
  loading: produit.loading,
  isConseiller: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.CONSEILLER])
});

const mapDispatchToProps = {
  getEntities,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Produit);
